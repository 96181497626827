<template>
  <ul class="message-list">

    <Message v-for="message in messages" :key="message.id" :message="message"/>

  </ul>
</template>

<script>
import Message from '@/components/chatroom/message/Container.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatroomMessages',

  components: {
    Message
  },

  computed: {
    ...mapGetters( 'Chatroom', {
      messages: 'messages'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.message-list {
  @include flex;
  flex-direction: column-reverse;
  padding: $page-padding;
  padding-top: 0;
}
</style>
