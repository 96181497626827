<template>
  <div class="chatroom">
    <div ref="scroller" v-on:scroll="scrolling" class="scroller">

      <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination inverse" :class="{ loading }">
        <p>{{ label }}</p>
        <img v-if="!loading" :src="icons.load" alt="Load more"/>
      </button>

      <List v-if="showMessages"/>

      <!-- Error and Loading -->
      <div v-else class="retry">
        <div>

          <h2 v-if="showError" class="bk">{{ error || 'No messages yet' }}</h2>
          <button role="button" v-if="showError" alt="Reload" @click="reload" class="submit circle retry">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><img :src="icons.reload" alt="Reload"/></span>
          </button>

          <Loading v-if="loading"/>

        </div>
      </div>

    </div>

    <Send :loaded="loaded" :scroll="scroll"/>

  </div>
</template>

<script>
import List from '@/components/chatroom/Messages.vue'
import Send from '@/components/chatroom/Send.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Chatroom',

  data() {
    return {
      text: '',
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' ),
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    List,
    Send,
    Loading
  },

  computed: {
    ...mapGetters( 'Chatroom', {
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'chatroom'
    }),

    max() {
      return this.limit.characters
    },

    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showMessages() {
      return !this.error && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showMessages
    },

    showError() {
      return this.error  && !this.loading
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Chatroom', {
      fetch: 'get'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return
      this.error = null
      this.loading = true

      const count = await this.fetch({ offset: this.offset }).catch( e => this.error = e )

      let scrollToBottom = !this.loaded
      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error

      if ( !scrollToBottom && this.scroller.scrollTop === 0 ) this.scroller.scrollTop = 1

      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
        if ( scrollToBottom ) this.scroll()
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.top( this.scroller )) this.load()
      if ( this.scroller.scrollTop === 0 && this.canLoadMore ) this.scroller.scrollTop = 1
    },

    scroll() {
      //Scrolls to the bottom
      if ( this.scroller ) this.scroller.scrollTop = this.scroller.scrollHeight
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.chatroom {
  position: relative;
  text-align: center;
  height: 100%;
  max-width: $max-width;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow-y: scroll;

  .scroller {
    overflow-y: scroll;
  }

  button.pagination {
    margin-top: 16px;
  }
}
</style>
