<template>
  <div class="bubble" :class="{ me: isMe }">

    <router-link v-if="!isMe" :title="name" :to="{ name: 'users', params: { id: message.user }}">
      <p>{{ name }}</p>
    </router-link>

    <p class="text">{{ message.text }}</p>
    <p class="date" :title="message.createdAt">{{ message.createdAt | date }}</p>

  </div>
</template>

<script>
import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatroomMessageContent',

  props: {
    message: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    isMe() {
      return this.me === this.message.user
    },

    name() {
      return this.$Utils.User.name( this.message )
    }
  }
}
</script>