<template>
  <form @submit.prevent="submit" :class="{ loaded }">
    
    <input type="text" placeholder="Say Hello" v-model="text" :maxlength="max"/>

  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChatroomSend',

  props: {
    scroll: Function,
    loaded: Boolean
  },

  data() {
    return {
      text: ''
    }
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'chatroom'
    }),

    max() {
      return this.limit.characters
    },

    canSend() {
      return this.text.trim() && this.loaded
    }
  },

  methods: {
    ...mapActions( 'Chatroom', {
      send: 'send'
    }),

    async submit() {
      if ( !this.canSend ) return
      this.send( this.text )
      this.text = ''
      
      this.$nextTick(() => {
        this.scroll()
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

form {
  padding: $page-padding;
  padding-top: 0;
  position: relative;
  width: 100%;

  &:not( .loaded ) {
    pointer-events: none;
  }
}
</style>